import { Button } from '@fabric-ds/react';
import bannerImg from '../../../public/banner-activity.png';
import styles from './FrontpageBanner.module.css';

const bannerContent = {
    header: 'Aktivitetsferie i høst?',
    text: 'Her finner du de mest populære aktivitetsferiene akkurat nå! Sykkelturer, skiferier og mye mer. Ha en gøy høst!',
    callToAction: 'Få inspirasjon her',
    link: 'https://www.finn.no/reise/artikler/aktivitet',
    img: {
        src: bannerImg.src,
        alt: 'En sykkel med sykkelveske står på en stille sti ved siden av en frodig, grønn eng, i naturlige omgivelser',
    },
};

export const FrontpageBanner = () => {
    const { header, text, callToAction, link, img } = bannerContent;

    return (
        <section className="flex flex-wrap flex-auto shadow-2 rounded-16 items-center justify-center md:flex-row-reverse md:flex-nowrap md:justify-between my-32">
            <div className={`w-full h-full overflow-hidden lg:rounded-lg ${styles.imgWrapper}`}>
                <img
                    className={`flex justify-center items-center text-center object-cover object-left min-w-full ${styles.img}`}
                    src={img.src}
                    alt={img.alt}
                />
            </div>
            <div className="p-32 lg:p-40 gap-y-16 lg:gap-y-20 text-center md:text-left container flex flex-col flex-auto justify-center items-center md:items-start">
                <div className="flex flex-col gap-y-8 md:gap-y-10">
                    <h2>{header}</h2>
                    <p>{text}</p>
                </div>
                <Button primary href={link} className="px-28 md:px-40">
                    {callToAction}
                </Button>
            </div>
        </section>
    );
};
