import { type PropsWithChildren, useEffect, useState } from 'react';

export type DropdownProps = {
    id: string;
    label?: string | undefined;
    text: string;
};

export const Dropdown = ({ id, label, text, children }: PropsWithChildren<DropdownProps>) => {
    const [isVisible, setIsVisible] = useState<boolean>();

    const toggle = (): void => setIsVisible(!isVisible);

    useEffect(() => {
        const isWithinDropdownContainer = (elem) =>
            elem !== document.body && elem && (elem.id === id || isWithinDropdownContainer(elem.parentElement));

        const closeOnOutsideClick = (event) => {
            if (!isWithinDropdownContainer(event.target)) {
                setIsVisible(false);
            }
        };

        if (isVisible) {
            window.addEventListener('click', closeOnOutsideClick, true);
            window.addEventListener('keyup', closeOnOutsideClick, true);
            window.addEventListener('touchstart', closeOnOutsideClick, true);
        } else {
            window.removeEventListener('click', closeOnOutsideClick, true);
            window.removeEventListener('keyup', closeOnOutsideClick, true);
            window.removeEventListener('touchstart', closeOnOutsideClick, true);
        }

        return () => {
            window.removeEventListener('click', closeOnOutsideClick, true);
            window.removeEventListener('keyup', closeOnOutsideClick, true);
            window.removeEventListener('touchstart', closeOnOutsideClick, true);
        };
    }, [isVisible]);

    return (
        <div id={id} className="input input--select relative">
            {/* biome-ignore lint/a11y/noLabelWithoutControl: biome is having trouble with label ids(?) */}
            {label && <label htmlFor={`${id}-dropdown`}>{label}</label>}
            <div className="input--select__wrap">
                <input
                    type="button"
                    value={text}
                    aria-label={text}
                    id={`${id}-dropdown`}
                    data-testid={`${id}-dropdown`}
                    aria-expanded={isVisible}
                    onClick={toggle}
                    onKeyUp={toggle}
                    className="text-left"
                />
            </div>
            {isVisible ? (
                <div className="absolute bg-white left-0 right-0 shadow p-16" style={{ zIndex: 2 }}>
                    {children}
                </div>
            ) : null}
        </div>
    );
};
